// colors

$color__white: #9a5105;
$color__grey--lighter: #e87906;
$color__grey--light: #53230e;
$color__black: black;
$color__black--darker: #A70000;




$media-bg1: #3b5999;
$media-bg2: #2b6b94;
$media-bg3: #27b4e8;
$media-bg4: #c32126;
$media-bg5: #04669a;
$media-bg6: #c82127;


.social-media-links--platforms {
  font-size: 20px;
  padding-left: 0;
  color: black;
}

.social-media-links--platforms.horizontal {

  padding-top: 34px;
  padding-bottom: 3px;
  // width: 84%;
  width: 100%;
  // margin-left: 8%;
  border-top: 4px solid #17a2b8;

  .block__content {
    text-align: center;
  }
  li {
   display: inline-block;
   text-align: center;
   padding: .2em .4em;
   margin: 0 0.5%;
 }
}



.region-copyright {
  
  .block__content {
    text-align: center;
    padding-top: 0;
  }
  .fa-2x {
    font-size: 1.5em;
  }

  .fa {
    display: inline-block;
    margin: 0;
    padding: 0;
    background: #406F9770;    
    border-radius: 27px;
    box-shadow: white 0px 0px 8px;
    // border: 4px solid #17a2b8;
    width: 48px;
    height: 48px;
    // line-height: 60px;


    &::before,
    &:hover::before {
      display: inline-block;
      width: 48px;
      line-height: 54px;
    }
  }


  .fa-facebook {
    &::before {
      content: url('../assets/social/fb-default.svg');
    }

    &:hover {
      background: $media-bg1;
      // border: 2px solid $media-bg1;

      &::before {

        content: url('../assets/social/fb-color.svg');
      }
    }
  }

  .fa-instagram {
    &::before {
      content: url('../assets/social/instagram-default.svg');
    }

    &:hover {
      background: $media-bg2;
      // border: 2px solid $media-bg2;

      &::before {

        content: url('../assets/social/instagram-color.svg');
      }
    }
  }

  .fa-twitter {
    &::before {
      content: url('../assets/social/twitter-default.svg');
    }

    &:hover {
      background: $media-bg3;
      // border: 2px solid $media-bg3;

      &::before {

        content: url('../assets/social/twitter-color.svg');
      }
    }
  }

  .fa-youtube {
    &::before {
      content: url('../assets/social/youtube-default.svg');
    }

    &:hover {
      background: $media-bg4;
      // border: 2px solid $media-bg4;

      &::before {

        content: url('../assets/social/youtube-color.svg');
      }
    }
  }


  .fa-linkedin {
    &::before {
      content: url('../assets/social/linkedin-default.svg');
    }

    &:hover {
      background: $media-bg5;
      // border: 2px solid $media-bg5;

      &::before {

        content: url('../assets/social/linkedin-color.svg');
      }
    }
  }

  .fa-pinterest {
    &::before {
      content: url('../assets/social/pinterest-default.svg');
    }

    &:hover {
      background: $media-bg6;
      // border: 2px solid $media-bg6;

      &::before {

        content: url('../assets/social/pinterest-color.svg');
      }
    }
  }

  

}
